.game-table {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.community-cards, .player-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
}

.card {
  width: 60px;
  height: 90px;
  border: 1px solid #000;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.player {
  margin: 10px;
  text-align: center;
}

.player-name {
  font-weight: bold;
  margin-bottom: 10px;
}
