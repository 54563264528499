.video-chat {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.video-chat video {
  width: 100%;
  height: auto;
  display: block;
}
