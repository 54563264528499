.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #333;
  padding: 10px;
}

.navbar a {
  color: white;
  text-decoration: none;
  padding: 14px 20px;
}

.navbar a:hover {
  background-color: #ddd;
  color: black;
}
