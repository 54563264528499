:root {
  --primary-color: #2C3E50;
  --secondary-color: #E74C3C;
  --accent-color: #F1C40F;
  --background-color: #ECF0F1;
  --text-color: #2C3E50;
  --heading-font: 'Playfair Display', serif;
  --body-font: 'Roboto', sans-serif;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--body-font);
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font);
  color: var(--primary-color);
}

button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  font-family: var(--body-font);
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: var(--secondary-color);
}

.navbar {
  background-color: var(--primary-color);
  padding: 10px 20px;
}

.navbar-brand {
  font-family: var(--heading-font);
  font-size: 1.5em;
  color: white;
}

.nav-link {
  color: white;
}

.nav-link:hover {
  color: var(--accent-color);
}

.game-table {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.video-chat-section {
  flex: 1;
  margin-right: 20px;
}

.poker-game-section {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-chat-section video {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.poker-game-section h2 {
  margin: 0;
  font-size: 1.5em;
}

.message {
  font-size: 1.2em;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .game-table {
    flex-direction: column;
  }

  .video-chat-section, .poker-game-section {
    flex: none;
    width: 100%;
    margin: 10px 0;
  }

  .video-chat-section video {
    width: 100%;
  }
}
